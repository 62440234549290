import React, { useEffect } from 'react';
import './styles.scss';
import { Spacer } from '../common';

interface IProps {}

const BooksPage = (props: IProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="books-page">
      <div className="top-section">
        <img src="aftereffects.jpg" alt="AfterEffects" />
      </div>
      <Spacer height={36} />
      <div className="purchase-section">
        <div className="section-heading">Buy Aftereffects at...</div>
        <div className="carousell">
          <a
            className="c-item"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.poetrybooks.co.uk/products/aftereffects-by-jiye-lee"
          >
            <img src="pbs.jpg" alt="Poetry Book Society" />
            <span>Poetry Book Society</span>
          </a>
          <a
            className="c-item"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.amazon.co.uk/Aftereffects-Jiye-Lee/dp/1913211517/ref=sr_1_1?crid=3G5EX4UBZPRZK&dib=eyJ2IjoiMSJ9.XxefAL5SH2Xg46RrNhYL3Q.Wr96pjqXN8pA1sDthtR_TDS6yLJ2Lvttixs969gPOT8&dib_tag=se&keywords=jiye+lee+aftereffects&qid=1726484855&sprefix=jiye+lee+aftereffect%2Caps%2C239&sr=8-1"
          >
            <img src="amazon.png" alt="Poetry Book Society" />
            <span>Amazon</span>
          </a>
          <a
            className="c-item"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.waterstones.com/book/aftereffects/jiye-lee/9781913211516"
          >
            <img src="waterstones.png" alt="Poetry Book Society" />
            <span>Waterstones</span>
          </a>
        </div>
      </div>
      <Spacer height={54} />
      <div className="section-heading">Reviews</div>
      <div className="review-section">
        <div className="section-heading">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://poetrybusiness.co.uk/product/the-north-67/"
          >
            <img src="the-north.png" alt="the north" />
            <span>The North</span>
          </a>
        </div>
        <div className="section-text">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://poetrybusiness.co.uk/product/the-north-67/"
          >
            https://poetrybusiness.co.uk/product/the-north-67/
          </a>
        </div>
      </div>
      <div className="review-section">
        <div className="section-heading">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/watch?v=9VrUE0FGLTg"
          >
            <img src="youtube.jpg" alt="youtube" />
            <span>PoetPri</span>
          </a>
        </div>
        <div className="section-text">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/watch?v=9VrUE0FGLTg"
          >
            https://www.youtube.com/watch?v=9VrUE0FGLTg
          </a>
        </div>
      </div>
      <Spacer height={48} />
      <div className="section-heading">Interviews</div>
      <div className="review-section">
        <div className="section-heading">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/watch?v=ptgrhKEP54I&t=61s&ab_channel=KBSWORLDEnglish"
          >
            <img src="kbs.png" alt="kbs" />
            <span>KBS</span>
          </a>
        </div>
        <div className="section-text">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/watch?v=ptgrhKEP54I&t=61s&ab_channel=KBSWORLDEnglish"
          >
            https://www.youtube.com/watch?v=ptgrhKEP54I&t=61s&ab_channel=KBSWORLDEnglish
          </a>
        </div>
      </div>
      <div className="review-section">
        <div className="section-heading">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.hoctok.com/jiye-lee.html"
          >
            <img src="hoctok.jpg" alt="hoctok" />
            <span>Hoktok Magazine</span>
          </a>
        </div>
        <div className="section-text">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.hoctok.com/jiye-lee.html"
          >
            http://www.hoctok.com/jiye-lee.html
          </a>
        </div>
      </div>
      <Spacer height={48} />
    </div>
  );
};

export default BooksPage;
