import React, { useEffect } from 'react';
import './styles.scss';
import { Spacer } from '../common';

interface IProps {}

const HomePage = (props: IProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="home-page">
      <div className="absolute">
        <div className="heading">
          International Poet <br />
          &amp; Storyteller
        </div>
        <div className="heading-2">International Poet &amp; Storyteller</div>
        <div className="desc">
          British-Korean. Globe Trotter. Lover of words, Matcha, and cake! I’m
          passionate about inspiring people of all ages through the magic of
          language, poetry, and storytelling. My favorite writers are Ocean
          Vuong, Sarah Kay, and C.S. Lewis just to name a few. Born in the U.K.,
          I currently find myself bouncing between Seoul, Kuala Lumpur, and
          Manchester. When I’m not writing, you’ll find me cafe hopping, hunting
          for good food, or desperately trying to achieve my reading goal on
          Goodreads!
        </div>
      </div>
      <div className="jiye-img">
        <img src="/jiye.png" alt="Jiye Lee" />
      </div>
      <div className="reviews">
        <div className="left">
          <img src="/aftereffects-cover.webp" alt="after-effects" />
        </div>
        <div className="right">
          <div className="review-box" style={{ fontWeight: '500' }}>
            Aftereffects is a moving and lyrical chapbook exploring the subtle
            reverberations of our lives. Jiye has an extraordinary ability to
            distill emotion into a few words, and refresh our perceptions of
            familiar images.
          </div>
          <div className="review-box">
            “Dedicated to her beloved father, Lee’s poems launch us straight
            into the bereavement and the bewilderment that accompanies it.
            Though her love and grief for her father is the golden thread that
            binds this pamphlet, she is never self-indulgent. Clear-eyed and
            open-hearted, Lee takes us around the world to witness beauty,
            catastrophe and social endeavour everywhere from Goa to Korea, Tyne
            & Wear to the Red Sea. Wherever her poet’s eye lands, she helps us
            to see the mortality and humanity that joins us all.”
            <div className="author">
              Kirsten Luckins
              <br />
              Poet and Project Manager of Rebecca Swift Foundation
            </div>
          </div>
        </div>
      </div>
      <Spacer height={84} />
    </div>
  );
};

export default HomePage;
