import React, { useEffect } from 'react';
import './styles.scss';
import { Spacer } from '../common';

interface IProps {}

interface Event {
  title: string;
  description?: string;
  date: string;
}

const pastEvents: Event[] = [
  {
    title: 'Butcher’s Dog Magazine Issue 18 Launch Event, online',
    date: 'May 26th 2023',
  },
  {
    title: 'Poetry Reading from ‘Aftereffects’—Fly on the Wall Press',
    description:
      'Fly on the Wall Press in collaboration with Manchester Poetry Library, Contact Theatre, Manchester',
    date: 'June 4th 2022',
  },
  {
    title:
      'Spoken Word performance for International Women’s Day, Levenshulme Old Library, Manchester',
    date: 'March 8th 2022',
  },
  {
    title: 'Poetry reading and Q & A, Sunderland University',
    description:
      'Guest speaker for ‘Writing Fiction and Poetry’ course students at Sunderland University.',
    date: 'November 23rd 2021',
  },
  {
    title: 'Fly on the Wall Press Summer Festival',
    description:
      'Poetry reading from chapbook ‘Aftereffects’ and writing workshop on loss and healing.',
    date: 'July 3rd 2021',
  },
];

const EventsPage = (props: IProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const removeUnwantedElement = () => {
      const unwantedElement = document.querySelector('a[href*="elfsight.com"]');
      if (unwantedElement) {
        unwantedElement.remove();
        return true;
      }
      return false;
    };

    removeUnwantedElement();

    const intervalId = setInterval(() => {
      if (removeUnwantedElement()) {
        clearInterval(intervalId);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="events-page">
      <div className="past-events">
        <h2 className="past-events__header">Future Events</h2>
        <h3 className="placeholder">Upcoming...</h3>
        <h2 className="past-events__header">Past Events</h2>
        <ul className="past-events__list">
          {pastEvents.map((event, index) => (
            <li key={index} className="past-events__item">
              <h3 className="past-events__title">{event.title}</h3>
              {event.description && (
                <p className="past-events__description">{event.description}</p>
              )}
              <p className="past-events__date">({event.date})</p>
            </li>
          ))}
        </ul>
      </div>
      <Spacer height={24} />
      <div className="ig-header">
        Follow Jiye&apos;s adventures, writings, and interests right here!
      </div>
      <Spacer height={24} />
      <div
        className="elfsight-app-89033569-67db-4614-9f0c-2bda10ee3a93"
        data-elfsight-app-lazy
      />
    </div>
  );
};

export default EventsPage;
