import React, { useEffect } from 'react';
import './styles.scss';

interface IProps {}

const AboutPage = (props: IProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="about-page">
      <div className="left-section">
        <div className="section-heading">About Jiye</div>
        <div className="section-text">
          <p>
            Jiye Lee is a British-Korean poet from Newcastle Upon Tyne. She did
            her undergraduate in Literature and Film Studies at the University
            of St.Andrews and her Creative Writing MA at Newcastle University.
            She has been fortunate enough to have lived all over the globe
            including Scotland, Malaysia, and South Korea. Her multicultural
            background often influences her writing, making cultural identity
            one of her key elements. Other common themes of her work are family,
            mental health struggles, female identity, love, and loss.
          </p>
          <p>
            Her main field is poetry, but she also writes short stories,
            creative non-fiction and screenplays. She has performed on stage for
            spoken word nights and her poems have been published in various
            literary magazines and broadcast on BBC platforms. In 2022, she
            received a DYCP grant from Arts Council England to work on her next
            poetry project. As of 2023, she has been longlisted for magazines
            such as Magma, The Rialto, and nominated for The Forward Prize for
            Best Single Poem.
          </p>
          <p>
            Jiye has taught English and Creative Writing to secondary school
            students in South Korea and also conducted creative writing
            workshops for schools in the North East of England for the{' '}
            <span className="heighlight">
              Lit&Phil Library’s Young Writers’ Award competition
            </span>
            . She is passionate about advocating the literary arts and
            instilling creativity and imagination in English education.
          </p>
        </div>
      </div>
      <div className="right-section">
        <img className="brand-image" src="/jiye-about.jpg" alt="Jiye Lee" />
      </div>
    </div>
  );
};

export default AboutPage;
