import React from 'react';
import './styles.scss';
import { LazyLoadedAudio, Spacer } from '../common';

interface IProps {}

const WritingPage = (props: IProps) => {
  return (
    <div className="writing-page">
      <div className="publications-section">
        <div className="section-heading">Publications</div>
        <div className="carousell">
          <a
            className="c-item"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.butchersdogmagazine.co.uk/product-page/butcher-s-dog-issue-18"
          >
            <img src="bd.png" alt="Butcher’s Dog Magazine" />
            <span className="first">Sago/사고</span>
            <span className="last">Butcher’s Dog Magazine</span>
          </a>
          <a
            className="c-item"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.propelmagazine.co.uk/jiye-lee-allegiance"
          >
            <img src="propel.jpg" alt="Propel Magazine" />
            <span className="first">Allegiance</span>
            <span className="last">Propel Magazine</span>
          </a>
          <a
            className="c-item"
            target="_blank"
            rel="noopener noreferrer"
            href="https://fragmentedvoices.com/2020/11/11/accident-anatomy-by-jiye-lee/"
          >
            <img src="FV.jpg" alt="Fragmented Voices" />
            <span className="first">Accident Anatomy</span>
            <span className="last">Fragmented Voices</span>
          </a>
        </div>
      </div>
      <Spacer height={48} />
      <div className="section-heading">Commissioned Projects</div>
      <ul className="comissions__list">
        <li className="comissions__item">
          <h3 className="comissions__title">
            Five hours to Jaipur (audio poem) - New Creatives North
          </h3>
          <p className="comissions__description">
            (Funded by Tyneside Cinema, Arts Council England & BBC Arts)
          </p>
          <Spacer height={16} />
          <LazyLoadedAudio audioSrc="/Five Hours to Jaipur.aac" />
          <Spacer height={16} />
        </li>
        <li className="comissions__item">
          <h3 className="comissions__title">Short Eats (book) - NicoNico</h3>
          <p className="comissions__description">
            (Collaboration project celebrating ESEA food and culture around
            cultural sayings and staple foods)
          </p>
        </li>
      </ul>
      <Spacer height={48} />
    </div>
  );
};

export default WritingPage;
