import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import './styles.scss';
import {
  HOME_PAGE,
  ABOUT_PAGE,
  EVENTS_PAGE,
  CONTACTS_PAGE,
  WRITING_PAGE,
  BOOKS_PAGE,
} from '../utils/urls';

interface IProps {}

const Header = (props: IProps) => {
  return (
    <div id="jiye-header">
      <div className="header">
        <Link to={HOME_PAGE}>
          <div className="brand">JIYE LEE</div>
        </Link>
        <div className="sub-brand">
          <span>Writer</span>
          <span>•</span>
          <span>Poet</span>
          <span>•</span>
          <span>Educator</span>
        </div>
      </div>
      <div className="navigation">
        <NavLink to={ABOUT_PAGE} activeClassName="active-link" exact>
          <span className="nav-item">About</span>
        </NavLink>
        <NavLink to={BOOKS_PAGE} activeClassName="active-link" exact>
          <span className="nav-item">Books</span>
        </NavLink>
        <NavLink to={WRITING_PAGE} activeClassName="active-link" exact>
          <span className="nav-item">Writing</span>
        </NavLink>
        <NavLink to={EVENTS_PAGE} activeClassName="active-link" exact>
          <span className="nav-item">Events</span>
        </NavLink>
        <NavLink to={CONTACTS_PAGE} activeClassName="active-link" exact>
          <span className="nav-item">Contact</span>
        </NavLink>
      </div>
    </div>
  );
};

export default Header;
