import React from 'react';
import './styles.scss';
import { FaInstagram, FaTiktok, FaTwitter } from 'react-icons/fa';

interface IProps {}

const Footer = (props: IProps) => {
  return (
    <div id="jiye-footer">
      <div className="button">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/jiyelee21/"
        >
          <FaInstagram />
        </a>
      </div>
      <div className="button">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/jiyelee21?lang=en"
        >
          <FaTwitter />
        </a>
      </div>
      <div className="button">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.tiktok.com/@jiyesarahlee"
        >
          <FaTiktok />
        </a>
      </div>
    </div>
  );
};

export default Footer;
