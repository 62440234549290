import React from 'react';
import { reactRouterHistory } from './utils/navigation';
import { Route, Router, Switch } from 'react-router-dom';
import Header from './header';
import HomePage from './home-page';
import {
  ABOUT_PAGE,
  BOOKS_PAGE,
  CONTACTS_PAGE,
  EVENTS_PAGE,
  HOME_PAGE,
  WRITING_PAGE,
} from './utils/urls';
import NotFoundPage from './http-responses/not-found';
import AboutPage from './about-page';
import BooksPage from './books-page';
import WritingPage from './writing-page';
import EventsPage from './events-page';
import ContactPage from './contact-page';
import Footer from './footer';

function App() {
  return (
    <Router history={reactRouterHistory}>
      <div id="jiye-main">
        <Header />
        <div className="jiye-container">
          <Switch>
            <Route exact path={HOME_PAGE} component={HomePage} />
            <Route exact path={ABOUT_PAGE} component={AboutPage} />
            <Route exact path={BOOKS_PAGE} component={BooksPage} />
            <Route exact path={WRITING_PAGE} component={WritingPage} />
            <Route exact path={EVENTS_PAGE} component={EventsPage} />
            <Route exact path={CONTACTS_PAGE} component={ContactPage} />
            <Route component={NotFoundPage} />
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
