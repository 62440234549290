import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';

interface LazyLoadedAudioProps {
  audioSrc: string;
}

const LazyLoadedAudio: React.FC<LazyLoadedAudioProps> = ({ audioSrc }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { ref } = useInView({
    triggerOnce: true,
    onChange: inView => {
      if (inView) setIsLoaded(true);
    },
  });

  return (
    <div ref={ref}>
      {isLoaded ? (
        <audio controls>
          <source src={audioSrc} type="audio/aac" />
          Your browser does not support the audio element.
        </audio>
      ) : (
        <p>Loading audio...</p>
      )}
    </div>
  );
};

export default LazyLoadedAudio;
