import { INavigation } from './interface';
import { createBrowserHistory } from 'history';

export const reactRouterHistory = createBrowserHistory();

export const RouterNavigation: INavigation = {
  currentPath: (): string => {
    return reactRouterHistory.location.pathname;
  },

  navigateTo: path => {
    reactRouterHistory.push(path);
  },

  popAndNavigateTo: path => {
    reactRouterHistory.replace(path);
  },

  backward: () => {
    reactRouterHistory.goBack();
  },

  forward: () => {
    reactRouterHistory.goForward();
  },
};
