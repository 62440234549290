import React, { useEffect, useState } from 'react';
import './styles.scss';

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  subject: string;
  message: string;
  checkboxes: {
    commissionsAndCollabs: boolean;
    workshopsAndReadings: boolean;
    interviewsAndReviews: boolean;
    generalInquiry: boolean;
  };
}

const ContactForm: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    message: '',
    checkboxes: {
      commissionsAndCollabs: false,
      workshopsAndReadings: false,
      interviewsAndReviews: false,
      generalInquiry: false,
    },
  });

  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});

  const validateField = (name: string, value: string) => {
    const errors: { [key: string]: string } = { ...formErrors };

    switch (name) {
      case 'firstName':
        errors.firstName = value.trim() ? '' : 'First Name is required';
        break;
      case 'email':
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        errors.email = value.trim()
          ? emailRegex.test(value)
            ? ''
            : 'Please enter a valid email address'
          : 'Email is required';
        break;
      case 'subject':
        errors.subject = value.trim() ? '' : 'Subject is required';
        break;
      case 'message':
        errors.message = value.trim() ? '' : 'Message is required';
        break;
      default:
        break;
    }

    setFormErrors(errors);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value, type, checked } = e.target as any;
    if (type === 'checkbox') {
      setFormData({
        ...formData,
        checkboxes: {
          ...formData.checkboxes,
          [name]: checked,
        },
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  const validateForm = () => {
    const errors: { [key: string]: string } = {};
    if (!formData.firstName.trim()) errors.firstName = 'First Name is required';
    if (!formData.email.trim()) errors.email = 'Email is required';
    if (!formData.subject.trim()) errors.subject = 'Subject is required';
    if (!formData.message.trim()) errors.message = 'Message is required';
    if (
      !formData.checkboxes.commissionsAndCollabs &&
      !formData.checkboxes.workshopsAndReadings &&
      !formData.checkboxes.interviewsAndReviews &&
      !formData.checkboxes.generalInquiry
    ) {
      errors.checkboxes = 'Please select at least one category';
    }
    setFormErrors(errors);
    return errors;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      const response = await fetch('https://formspree.io/f/xkgnowpb', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        alert('Form submitted successfully!');
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          subject: '',
          message: '',
          checkboxes: {
            commissionsAndCollabs: false,
            workshopsAndReadings: false,
            interviewsAndReviews: false,
            generalInquiry: false,
          },
        });
        setFormErrors({});
      } else {
        alert('There was an issue submitting the form.');
      }
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <form className="contact-form" onSubmit={handleSubmit}>
      <h2>Contact me ...</h2>
      <div className="form-group">
        <label htmlFor="firstName">
          Name <span>(required)</span>
        </label>
        <div className="name-fields">
          <div>
            <input
              type="text"
              id="firstName"
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {formErrors.firstName && (
              <span className="error">{formErrors.firstName}</span>
            )}
          </div>
          <div>
            <input
              type="text"
              id="lastName"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="email">
          Email Address <span>(required)</span>
        </label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Email Address"
          value={formData.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {formErrors.email && <span className="error">{formErrors.email}</span>}
      </div>

      <div className="form-group">
        <label htmlFor="subject">
          Subject <span>(required)</span>
        </label>
        <input
          type="text"
          id="subject"
          name="subject"
          placeholder="Subject"
          value={formData.subject}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {formErrors.subject && (
          <span className="error">{formErrors.subject}</span>
        )}
      </div>

      <div className="form-group">
        <label>
          Interested In: <span>(required)</span>
        </label>
        <div className="checkbox-group">
          <div className="checkbox-group-item">
            <span>Commissions / Collabs</span>
            <input
              type="checkbox"
              name="commissionsAndCollabs"
              checked={formData.checkboxes.commissionsAndCollabs}
              onChange={handleChange}
            />
          </div>
          <div className="checkbox-group-item">
            <span>Workshops / Readings</span>
            <input
              type="checkbox"
              name="workshopsAndReadings"
              checked={formData.checkboxes.workshopsAndReadings}
              onChange={handleChange}
            />
          </div>
          <div className="checkbox-group-item">
            <span>Interviews / Reviews</span>
            <input
              type="checkbox"
              name="interviewsAndReviews"
              checked={formData.checkboxes.interviewsAndReviews}
              onChange={handleChange}
            />
          </div>
          <div className="checkbox-group-item">
            <span>General Inquiry</span>
            <input
              type="checkbox"
              name="generalInquiry"
              checked={formData.checkboxes.generalInquiry}
              onChange={handleChange}
            />
          </div>
        </div>
        {formErrors.checkboxes && (
          <span className="error">{formErrors.checkboxes}</span>
        )}
      </div>

      <div className="form-group">
        <label htmlFor="message">
          Message <span>(required)</span>
        </label>
        <textarea
          id="message"
          name="message"
          placeholder="Message"
          value={formData.message}
          onChange={handleChange}
          onBlur={handleBlur}
        ></textarea>
        {formErrors.message && (
          <span className="error">{formErrors.message}</span>
        )}
      </div>

      <button type="submit">Submit</button>
    </form>
  );
};

export default ContactForm;
